import './rafPolyfill';

const ANIMATION_FACTOR = 25;

export function scrollElementTo(element, to) {
  const elementPositionTop = Math.floor(element.offsetTop);
  const targetTop = Math.abs(elementPositionTop - to);

  let elementPosition = Math.abs(elementPositionTop);
  const scrollDiff = targetTop - elementPosition;
  const tick = scrollDiff / ANIMATION_FACTOR;
  let alpha = 0;

  const animation = () => {
    elementPosition += tick;
    alpha += Math.abs(tick);
    element.scrollTop = elementPosition;

    if (alpha + Math.abs(tick) < Math.abs(scrollDiff)) {
      window.requestAnimationFrame(animation);
    } else {
      element.scrollTop = targetTop;
    }
  };

  window.requestAnimationFrame(animation);
}
