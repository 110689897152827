import React, {JSX} from 'react';

const defaultTag = 'div';
type AllowedTags = keyof JSX.IntrinsicElements;

export type Props<T extends AllowedTags> = {
  /**
   * Element tag to render.
   *
   * @default div
   **/
  as?: T;
  /** Forwards the ref to the element. */
  forwardRef?: React.Ref<HTMLElement>;
  className?: string;
} & React.ComponentPropsWithoutRef<T>;

export const Base = <T extends AllowedTags = typeof defaultTag>({
  as,
  forwardRef,
  ...rest
}: Props<T>): JSX.Element =>
  React.createElement(as || defaultTag, {ref: forwardRef, ...rest});

Base.displayName = 'Base';

export const BaseArgTypes = {
  as: {
    control: {
      disable: true,
    },
    table: {
      type: {
        summary: 'keyof JSX.IntrinsicElements',
      },
    },
  },
  className: {
    control: {
      disable: true,
    },
  },
  forwardRef: {
    table: {
      type: {
        summary: 'React.Ref',
      },
    },
  },
};
