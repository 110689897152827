import React from 'react';
import styles from './ListLinks.module.css';
import { Tabs } from './Navigation';

interface Props {
  items: Tabs['child-items'];
  uriLocationReference: string;
  onLinkClick: (label: string, index: number, redirectUri: string) => void;
}

export function ListLinks(props: Props): React.ReactElement {
  const uriFromUriTemplate = (
    template: string,
    uriLocationReference: string
  ): string => {
    return (template || '').replace(
      '/{{locationOrPostalRef}}',
      uriLocationReference ? `/${uriLocationReference}` : ''
    );
  };

  const handleOnClick = (event: React.MouseEvent): void => {
    event.preventDefault();

    const eventTarget = event.target as HTMLAnchorElement;
    const label = eventTarget.dataset.label;
    const index = eventTarget.dataset.index;

    if (label && index) {
      props.onLinkClick(label, parseInt(index, 10), eventTarget.href);
    }
  };

  return (
    <ul className={styles.list}>
      {props.items
        .filter((item) => item.enabled === 'true')
        .map((item, index: number) => (
          <li key={index} className={styles.item}>
            <a
              className={styles.link}
              href={uriFromUriTemplate(
                item['uri-template'],
                props.uriLocationReference
              )}
              onClick={handleOnClick}
              data-label={item.label}
              data-index={index}
            >
              {item.label}
            </a>
          </li>
        ))}
    </ul>
  );
}
