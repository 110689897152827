import React from 'react';
import {
  mParticleLogPageView,
  mParticleTrackSessionAttributes,
  useDevice,
} from '@treatwell/ui';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { Context } from '../components/LocaleWrapper';

const UTM_FIELDS = [
  'utm_campaign',
  'utm_content',
  'utm_marketing',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_campaign_id',
  'utm_ad_id',
  'gclid',
];

const getNavigationEntry = (): PerformanceNavigationTiming | void => {
  const navigationEntry =
    window.performance &&
    performance.getEntriesByType &&
    (performance.getEntriesByType(
      'navigation'
    )[0] as PerformanceNavigationTiming);

  // Check to ensure the `responseStart` property is present and valid.
  // In some cases no value is reported by the browser (for
  // privacy/security reasons), and in other cases (bugs) the value is
  // negative or is larger than the current page time.
  if (
    navigationEntry &&
    navigationEntry.responseStart > 0 &&
    navigationEntry.responseStart < performance.now()
  ) {
    return navigationEntry;
  }
};

export function getUtmParameters(parameters: string) {
  const utmParameters = Object.fromEntries(
    [...new URLSearchParams(parameters).entries()].filter(([key]) =>
      UTM_FIELDS.includes(key)
    )
  );
  return utmParameters;
}

type PageLoadMetrics = {
  ['full_page_load_time']: string;
  ['server_response_time']: string;
  ['dom_content_load_time']: string;
  ['ttfb_load_time']: string;
  ['dom_complete_load_time']: string;
};

const getReportPageLoadMetrics = (): PageLoadMetrics | void => {
  const navigationEntry = getNavigationEntry();

  if (!navigationEntry) {
    return;
  }

  return {
    full_page_load_time: (
      navigationEntry.loadEventEnd - navigationEntry.startTime
    ).toFixed(2),
    server_response_time: (
      navigationEntry.responseEnd - navigationEntry.fetchStart
    ).toFixed(2),
    dom_content_load_time: (
      navigationEntry.domContentLoadedEventEnd - navigationEntry.startTime
    ).toFixed(2),
    ttfb_load_time: (
      navigationEntry.responseStart - navigationEntry.startTime
    ).toFixed(2),
    dom_complete_load_time: (
      navigationEntry.domComplete - navigationEntry.startTime
    ).toFixed(2),
  };
};

const getPageViewAttributes = (
  countryCode: ChannelOutput['country']['countryCode'],
  { href, pathname, search }: Location,
  { referrer }: Document
) => ({
  country: countryCode,
  platform: 'web',
  url: href,
  url_path: pathname,
  query_parameters: search,
  referrer,
});

export function useTrackPageAndSessionView() {
  const { channel } = React.useContext(Context);
  const countryCode = channel.country.countryCode;

  React.useEffect(() => {
    const pageViewAttributes = getPageViewAttributes(
      countryCode,
      window.location,
      document
    );
    const utmParameters = getUtmParameters(window.location.search);

    mParticleLogPageView('page_view', {
      ...pageViewAttributes,
      ...utmParameters,
    });

    mParticleTrackSessionAttributes(countryCode, utmParameters);
  }, [countryCode]);
}

export const TrackBrowsePageAndSessionView = ({
  hasPopularTreatments,
  children,
  treatmentType,
  treatment,
}: {
  hasPopularTreatments: boolean;
  children: React.ReactNode;
  treatmentType?: string;
  treatment?: string;
}) => {
  const { channel } = React.useContext(Context);
  const { isMobile } = useDevice();
  const countryCode = channel.country.countryCode;

  React.useEffect(() => {
    const utmParameters = getUtmParameters(window.location.search);
    const pageViewAttributes = getPageViewAttributes(
      countryCode,
      window.location,
      document
    );

    mParticleLogPageView('browse', {
      section: 'exploration',
      is_web_mobile: isMobile,
      treatment_type: treatmentType,
      popular_treatments_displayed: hasPopularTreatments,
      treatment,
      ...pageViewAttributes,
      ...utmParameters,
    });

    mParticleTrackSessionAttributes(countryCode, utmParameters);
  }, [countryCode, hasPopularTreatments, isMobile, treatment, treatmentType]);

  return children;
};

export const TrackVenuePageAndSessionView = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { channel, pageData } = React.useContext(Context);
  const countryCode = channel.country.countryCode;
  const venueId = pageData.venue.venue.id.toString();

  React.useEffect(() => {
    const utmParameters = getUtmParameters(window.location.search);
    const pageViewAttributes = getPageViewAttributes(
      countryCode,
      window.location,
      document
    );
    const loadSpeedMetrics = getReportPageLoadMetrics();

    mParticleLogPageView('venue_menu', {
      section: 'booking_flow',
      venue_id: venueId,
      ...pageViewAttributes,
      ...utmParameters,
      ...loadSpeedMetrics,
    });

    mParticleTrackSessionAttributes(countryCode, utmParameters);
  }, [countryCode, venueId]);

  return children;
};
