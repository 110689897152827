import React from 'react';

import classnames from 'classnames';
import styles from './Button.module.css';

const Button = ({
  className,
  label,
  type = 'main',
  children,
  processing,
  ...otherProps
}) => {
  const buttonClasses = classnames(styles.button, styles[type], className, {
    [styles.processing]: processing,
  });
  const text = label || children;

  return (
    <button className={buttonClasses} {...otherProps}>
      {text}
    </button>
  );
};

export default Button;
