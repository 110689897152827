import React, { Component } from 'react';
import Select from 'react-select';
import clsx from 'clsx';
import styles from './Select.module.css';
// import "!style!css!react-select/dist/react-select.css";

function getValueFromProps(props) {
  if ('value' in props) {
    /*
     *  Destructure the value from the props
     */
    const { value } = props;

    return value;
  }
  /*
   *  Destructure value property of first item in the props.options
   *
   *  By providing a default "options" array in "defaultProps"
   *  it should always be available, so there's no need to test for it
   */
  const [{ value } = {}] = props.options;

  return value;
}

class TwSelect extends Component {
  static defaultProps = {
    options: [],
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};

    if (!props.placeholder) {
      const value = getValueFromProps(props);

      this.state = {
        value,
      };
    }
  }

  componentDidUpdate() {
    if (this.props.placeholder) {
      return;
    }

    const value = getValueFromProps(this.props);
    if (value !== this.state.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value });
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ value: selectedOption });
    /*
     *  By providing a default "onChange" function in "defaultProps"
     *  it should always be available, so there's no need to test for it
     */
    this.props.onChange(selectedOption);
  };

  render() {
    const { options, compact = false, placeholder } = this.props;
    const { value } = this.state;
    console.log({ value });
    const className = clsx(
      {
        [styles.select]: true,
        [styles.compact]: compact,
      },
      this.props.positioningClassName
    );

    return (
      <div className={className}>
        <Select
          options={options}
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export default TwSelect;
