import React from 'react';

import NewsletterSubscribe from '../NewsletterSubscribe';
import Popup from '../Popup';

class NewsletterSubscribePopup extends React.Component {
  getCMSItems = () => {
    const { i18n } = this.props;
    const cmsText = 'common.newsletter-subscribe-popup.text';
    const cmsColors = 'common.newsletter-subscribe-popup.colors';
    const cmsImages = 'common.newsletter-subscribe-popup.images';

    return {
      headerText: i18n(`${cmsText}.header`),
      placeholderName: i18n(`${cmsText}.placeholder-name`),
      placeholderEmail: i18n(`${cmsText}.placeholder-email`),
      placeholderPostcode: i18n(`${cmsText}.placeholder-postcode`),
      placeholderGender: i18n(`${cmsText}.placeholder-gender`),
      genderMale: i18n(`${cmsText}.gender-male`),
      genderFemale: i18n(`${cmsText}.gender-female`),
      textCheckbox: i18n(`${cmsText}.checkbox`),
      buttonSubmit: i18n(`${cmsText}.button-submit`),
      textSuccess: i18n(`${cmsText}.success`),
      buttonClose: i18n(`${cmsText}.button-close`),
      textFailure: i18n(`${cmsText}.failure`),
      controlsBackgroundColor: i18n(`${cmsColors}.controls-background-color`),
      controlsForegroundColor: i18n(`${cmsColors}.controls-foreground-color`),
      backgroundImageUrlMobile: i18n(
        `${cmsImages}.background-image-url-mobile`
      ),
      backgroundImageUrlDesktop: i18n(
        `${cmsImages}.background-image-url-desktop`
      ),
    };
  };

  render() {
    const {
      textColor,
      showPostcodeInput,
      privacyUri,
      i18n,
      activeView,
      controlsForegroundColor,
      controlsBackgroundColor,
      onSubmit,
      onClose,
    } = this.props;
    const cmsItems = this.getCMSItems();

    return (
      <Popup
        header={false}
        maximiseOnMobile
        noCloseButton
        i18n={i18n}
        sizeContent
        width={800}
        height={480}
        onClose={onClose}
      >
        <NewsletterSubscribe
          textColor={textColor}
          showPostcodeInput={showPostcodeInput}
          privacyUri={privacyUri}
          cmsItems={cmsItems}
          controlsForegroundColor={controlsForegroundColor}
          controlsBackgroundColor={controlsBackgroundColor}
          onSubmit={onSubmit}
          onClose={onClose}
          activeView={activeView}
        />
      </Popup>
    );
  }
}

export default NewsletterSubscribePopup;
