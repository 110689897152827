import React from 'react';
import { Button, ButtonColour, ButtonSize } from 'js/components/Button';
import { ButtonAnchor } from 'js/components/ButtonAnchor/ButtonAnchor';
import styles from './HighPriorityMessagingBannerView.module.css';

interface Props {
  heading: string;
  text: string;
  closeButtonLabel: string;
  moreInfoButton?: {
    label: string;
    url: string;
  };
  onCloseClick?: () => void;
}

export function HighPriorityMessagingBannerView(
  props: Props
): React.ReactElement | null {
  return (
    <div className={styles.container}>
      <div className={styles.overlay} onClick={props.onCloseClick} />
      <div className={styles.banner}>
        <div className={styles.pageWidthContainer}>
          <div className={styles.contentContainer}>
            <h3 className={styles.heading}>{props.heading}</h3>
            {props.text.split('\n').map((paragraph) => (
              <p className={styles.textParagraph} key={paragraph}>
                {paragraph}
              </p>
            ))}
            <div className={styles.buttonContainer}>
              {props.moreInfoButton !== undefined && (
                <div className={styles.button}>
                  <ButtonAnchor
                    colour={ButtonColour.BlueOutline}
                    size={ButtonSize.Medium}
                    label={props.moreInfoButton.label}
                    href={props.moreInfoButton.url}
                  />
                </div>
              )}
              <Button
                positioningClassname={styles.button}
                colour={ButtonColour.Blue}
                size={ButtonSize.Medium}
                label={props.closeButtonLabel}
                onClick={props.onCloseClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
