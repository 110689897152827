import React from 'react';
import { Logo } from 'js/components/Logo';
import { Button, ButtonColour } from 'js/components/Button';
import styles from './styles.module.css';
import { getTranslatedError } from './getTranslatedError';

interface Props {
  ssrUrlInfo?: {
    hostname: string;
    pathname: string;
    languageCode?: string;
  };
}

// This component can render something when all hope is lost, and
// not even cms or language is necessarily known.
export function LastResortErrorPage(props: Props): React.ReactElement {
  const { ssrUrlInfo } = props;
  let translatedError = getTranslatedError('www.treatwell.co.uk', '/');
  let hostname = '';
  if (ssrUrlInfo) {
    translatedError = getTranslatedError(
      ssrUrlInfo.hostname,
      ssrUrlInfo.pathname,
      ssrUrlInfo.languageCode
    );

    hostname = ssrUrlInfo.hostname;
  }
  if (typeof window !== 'undefined') {
    translatedError = getTranslatedError(
      window.location.hostname,
      window.location.pathname
    );

    hostname = window.location.hostname;
  }

  const isTwMarketplace = hostname.startsWith('www.');
  const isWidget = ['book.', 'buchung.', 'widget.'].some((prefix) =>
    hostname.startsWith(prefix)
  );

  return (
    <div className={styles.container}>
      {isTwMarketplace && (
        <>
          <hr className={styles.horizontalRule} />
          <Logo uri="/" positioningClassname={styles.logo} />
        </>
      )}

      <div className={styles.content}>
        <div className={styles.title}>{translatedError.title}</div>
        <div className={styles.message}>{translatedError.message}</div>
      </div>

      {!isWidget && (
        <a className={styles.link} href="/">
          <Button
            label={translatedError.buttonLabel}
            colour={ButtonColour.Blue}
          />
        </a>
      )}
    </div>
  );
}
