import React, {JSX} from 'react';
import clsx from 'clsx';

import {TextStyle} from '@treatwell/design-tokens';

import styles from './Text.module.css';
import {Base, BaseProps} from '../../Internal/Base';

const defaultTag = 'span';
export const allowedTags = [
  defaultTag,
  'div',
  'p',
  'label',
  's',
  'em',
  'b',
  'i',
  'dd',
  'dt',
  'small',
  'time',
  'strong',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
] as const;
export type AllowedTags = typeof allowedTags[number];

export type TextProps<T extends AllowedTags = typeof defaultTag> = {
  children: React.ReactNode;
  type?: TextStyle;
  htmlFor?: string;
} & BaseProps<T>;

/**
 * Renders text blocks in styles to fit the purpose.
 */
export const Text = <T extends AllowedTags>({
  type = 'body',
  className,
  as,
  ...props
}: TextProps<T>): JSX.Element => (
  <Base
    as={as || defaultTag}
    className={clsx(styles[type], className)}
    {...props}
  >
    {props.children}
  </Base>
);

Text.displayName = 'Text';
