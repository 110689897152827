import flattenDeep from 'lodash.flattendeep';
import {
  ALL_TREATMENT_TYPES_ID,
  BODY_ID,
  FACE_ID,
  HAIR_ID,
  NAILS_ID,
  MASSAGE_ID,
  HAIR_REMOVAL_ID,
  MEDICAL_DENTAL_ID,
  YOGA_PILATES_ID,
  DANCE_ID,
  FITNESS_ID,
  PHYSICAL_THERAPY_ID,
  COUNSELLING_HOLISTIC_ID,
} from 'js/constants/treatment-types';
import { SUGGESTED_SERVICES } from 'js/constants/venue-menu';
import { Menu, MenuGroup } from 'js/model/rainbow/venue/VenueOutput';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import {
  DurationRange,
  OptionGroup,
  TreatmentVenueMenuItemOutput,
} from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';
import { TreatmentCategoryGroupItem } from 'js/model/rainbow/TreatmentCategoryGroupOutput';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { CmsVenueMenu } from 'js/model/cms/cms-venue';
import { formatDuration } from './formatters';

interface MenuItem {
  menuGroup?: MenuGroup;
  item?: VenueMenuItemTypeOutput;
}

interface TreatmentGroup {
  id: number;
  name: string;
  priceRange: PriceRangeOutput;
  menuItems: VenueMenuItemTypeOutput[];
}

export const getItemByServiceId = (
  serviceId: string | undefined,
  menuGroups: MenuGroup[]
): MenuItem => {
  const items = flattenDeep(menuGroups.map((menuGroup) => menuGroup.menuItems));

  const menuGroup = menuGroups.find(
    (group) =>
      group.menuItems.find(
        (menuItem) => menuItem.data.id.substr(2) === serviceId
      ) // Remove the TP or TR part of service id
  );
  return {
    menuGroup,
    item: items.find((menuItem) => menuItem.data.id.substr(2) === serviceId), // Remove the TP or TR part of service id
  };
};

/** Sort treatment types according to CMS-defined order.
 * Items not included in the order parameter are placed at the end of the array, sorted by ID (ascending).
 */
export const sortTreatmentTypes = (
  treatmentTypes: { id: number; name: string }[],
  order: number[]
): { id: number; name: string }[] => {
  const tt = [...treatmentTypes];
  tt.sort((a, b) => {
    const aIndex = order.indexOf(a.id);
    const bIndex = order.indexOf(b.id);
    if (aIndex > -1 && bIndex > -1) {
      return aIndex - bIndex;
    }

    // elements with IDs in order parameter take priority
    if (aIndex > -1) return -1;
    if (bIndex > -1) return 1;

    // for items not represented in `order`: compare numeric IDs
    return a.id - b.id;
  });
  return tt;
};

const filterTreatmentGroups = (
  menuGroups: MenuGroup[],
  activeTreatmentTypeId: number
): TreatmentGroup[] =>
  menuGroups.map((group) => {
    const menuItems = group.menuItems.filter(
      (item) =>
        item.data.primaryTreatmentCategoryGroupId === activeTreatmentTypeId
    );

    return { ...group, menuItems };
  });

export const filterGroupsByActiveTreatmentType = (
  menuGroups: MenuGroup[],
  activeTreatmentTypeId: number
): TreatmentGroup[] => {
  let filteredMenuGroups;

  switch (activeTreatmentTypeId) {
    case ALL_TREATMENT_TYPES_ID:
      return menuGroups;
    default:
      filteredMenuGroups = filterTreatmentGroups(
        menuGroups,
        activeTreatmentTypeId
      );
  }

  return filteredMenuGroups
    .filter((group) => group.menuItems.length > 0)
    .filter((group) => group.id.toString() !== SUGGESTED_SERVICES);
};

export const getTreatmentTypeId = (menuItem: VenueMenuItemTypeOutput): number =>
  menuItem.data.primaryTreatmentCategoryGroupId;

export const getPseudoTreatmentTypes = (
  cms: CmsVenueMenu
): TreatmentCategoryGroupItem[] => {
  const all = {
    id: ALL_TREATMENT_TYPES_ID,
    name: cms['treatment-type-all'],
  } as TreatmentCategoryGroupItem;

  return [all];
};

export const getFirstMenuItemSkuId = (
  optionsData: TreatmentVenueMenuItemOutput
): string | null | 0 => {
  const getSkuId = (group: OptionGroup) =>
    group.options && group.options.length ? group.options[0].id : null;
  return !optionsData.optionGroups
    ? getSkuId(optionsData.optionGroups)
    : optionsData.optionGroups.length && getSkuId(optionsData.optionGroups[0]);
};

export const isValidTreatmentType = (treatmentTypeId: number): boolean =>
  [
    ALL_TREATMENT_TYPES_ID,
    BODY_ID,
    FACE_ID,
    HAIR_ID,
    NAILS_ID,
    MASSAGE_ID,
    HAIR_REMOVAL_ID,
    MEDICAL_DENTAL_ID,
    YOGA_PILATES_ID,
    DANCE_ID,
    FITNESS_ID,
    PHYSICAL_THERAPY_ID,
    COUNSELLING_HOLISTIC_ID,
  ].includes(treatmentTypeId);

export const getPrimaryMenuItem = (
  menu: Menu,
  serviceIds?: string
): MenuItem | null => {
  const primaryServiceId = serviceIds && serviceIds.split(',')[0];
  const menuItem =
    getItemByServiceId(primaryServiceId?.substring(2), menu.menuGroups) || {};

  return menuItem.item ? menuItem : null;
};

export function generateDuration(
  durationRange: DurationRange,
  cms: { hour: string; hours: string; minutes: string }
): string {
  const minDuration = durationRange.minDurationMinutes;
  const formattedMinDuration = formatDuration(minDuration, cms);
  const maxDuration = durationRange.maxDurationMinutes;
  const formattedMaxDuration = formatDuration(maxDuration, cms);

  return minDuration !== maxDuration
    ? `${formattedMinDuration} - ${formattedMaxDuration}`
    : `${formattedMinDuration}`;
}
