import React, {JSX} from 'react';
import clsx from 'clsx';

import styles from './VisuallyHidden.module.css';
import {Base, BaseProps} from '../../Internal/Base';

export type VisuallyHiddenProps = {
  children?: React.ReactNode;
} & BaseProps<keyof JSX.IntrinsicElements>;

/**
 * VisuallyHidden is a common technique used in web accessibility to hide content
 * from the visual client, but keep it readable for screen readers.
 */
export const VisuallyHidden = ({className, ...props}: VisuallyHiddenProps) => (
  <Base className={clsx(styles.visuallyHidden, className)} {...props}>
    {props.children}
  </Base>
);

VisuallyHidden.displayName = 'VisuallyHidden';
