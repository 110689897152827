import * as SentryNode from '@sentry/node';
import * as SentryBrowser from '@sentry/browser';
import { SENTRY_DSN } from 'js/constants/sentry';
import { twEnv } from 'js/helpers/environment';

export const isSentryDebugMode = process.env.TW_SENTRY_DEBUG === 'true';

export const getSentryOptions = <
  SentryOptions extends SentryNode.NodeOptions | SentryBrowser.BrowserOptions,
>(
  sentryOptions: SentryOptions
): SentryOptions => {
  const options: SentryOptions = {
    ...sentryOptions,
    dsn: SENTRY_DSN,
    environment: isSentryDebugMode ? 'twbox' : twEnv,
    debug: isSentryDebugMode,
  };

  if (twEnv === 'prod') {
    return options;
  }

  options.tracesSampler = (context) => {
    if (isSentryDebugMode && sentryOptions.tracesSampler) {
      return sentryOptions.tracesSampler(context);
    }

    return false;
  };

  options.beforeSendTransaction = (event, hint) => {
    if (isSentryDebugMode) {
      if (sentryOptions.beforeSendTransaction) {
        return sentryOptions.beforeSendTransaction(event, hint);
      }

      return event;
    }

    return null;
  };

  options.beforeSend = (event, hint) => {
    if (isSentryDebugMode) {
      if (sentryOptions.beforeSend) {
        return sentryOptions.beforeSend(event, hint);
      }

      return event;
    }

    return null;
  };

  return options;
};
