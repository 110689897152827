import React from 'react';
import styles from './Promotion.module.css';

export type Promo = {
  imageUri: string;
  uri: string;
  heading: string;
};

interface Props {
  promo: Promo;
  uriLocationReference: string;
  active: boolean;
  index: number;
  onClick: (label: string, index: number, redirectUri: string) => void;
}

export function Promotion(props: Props): React.ReactElement {
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);

  const uriFromUriTemplate = (
    template: string,
    uriLocationReference: string
  ): string => {
    return (template || '').replace(
      '/{{locationOrPostalRef}}',
      uriLocationReference ? `/${uriLocationReference}` : ''
    );
  };

  const handleImageLoad = (): void => {
    setImageLoaded(true);
  };

  const renderPlaceholder = (): React.ReactNode => {
    return (
      <svg viewBox="0 0 335 167">
        <g>
          <rect
            style={{ fill: '#e8e8e8', stroke: 'none' }}
            width="335"
            height="167"
          />
        </g>
      </svg>
    );
  };

  const handleOnClick = (event: React.MouseEvent): void => {
    event.preventDefault();

    const eventTarget = event.currentTarget as HTMLAnchorElement;
    const label = eventTarget.dataset.label;
    const index = eventTarget.dataset.index;
    const href = eventTarget.href;

    if (label && index) {
      props.onClick(label, parseInt(index, 10), href);
    }
  };

  let image;

  if (props.active) {
    image = imageLoaded ? (
      <img className={styles.img} src={props.promo.imageUri} />
    ) : (
      <div>
        <img
          style={{ display: 'none' }}
          src={props.promo.imageUri}
          onLoad={handleImageLoad}
        />
        {renderPlaceholder()}
      </div>
    );
  } else {
    image = renderPlaceholder();
  }

  return (
    <a
      className={styles.promo}
      data-label={props.promo.heading}
      data-index={props.index}
      href={uriFromUriTemplate(props.promo.uri, props.uriLocationReference)}
      onClick={handleOnClick}
    >
      <>
        {image}
        <div className={styles.heading}>{props.promo.heading}</div>
      </>
    </a>
  );
}
