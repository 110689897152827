const cloneeClassName = 'clonee';
const clonedClassName = 'cloned';

/*
 * Clone an info columns block container and its children, and add the
 * clone as a hidden sibling of the container.
 */
export const cloneInfoColumnsContainer = (container) => {
  const clonedContainer = container.cloneNode(true);

  clonedContainer.style.display = 'none';
  container.parentNode.insertBefore(clonedContainer, null);

  // eslint-disable-next-line no-param-reassign
  container.className = `${container.className} ${cloneeClassName}`;
  clonedContainer.className = `${clonedContainer.className} ${clonedClassName}`;
};

/*
 * Remove all manipulated (in './layout.js') info column containers, and show the cloned
 * containers. This should re-instate the DOM that react expects.
 */
export const removeClonedInfoColumnContainers = () => {
  Array.prototype.slice
    .call(document.querySelectorAll(`.${cloneeClassName}`))
    .forEach((clonee) => {
      clonee.parentNode.removeChild(clonee);
    });

  Array.prototype.slice
    .call(document.querySelectorAll(`.${clonedClassName}`))
    .forEach((cloned) => {
      // eslint-disable-next-line no-param-reassign
      cloned.style.display = 'block';
    });
};
