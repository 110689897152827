import React from 'react';
import Cookies from 'js-cookie';

import { Dropdown, DropdownItem, Text } from '@treatwell/ui';
import styles from './styles.module.css';

interface Language {
  displayName: string;
  code: string;
}

interface SelectProps {
  value: string;
  label: string;
  uri?: string;
}

type LanguageSelectLinks = Language & SelectProps;

interface Props {
  alternateLocaleUris: {
    locale: string;
    uri: string;
  }[];
  languageCode: string;
  countryCode: string;
  availableLanguages: Language[];
}

export function LanguageSelector({
  alternateLocaleUris,
  availableLanguages,
  countryCode,
  languageCode,
}: Props): React.ReactElement | null {
  const onSelected = React.useCallback((selectedLanguageCode: string) => {
    if (selectedLanguageCode === languageCode) {
      return;
    }

    Cookies.set('preferred_language', selectedLanguageCode, { expires: 365 });
    window.location.assign(
      `${getLocaleUri(selectedLanguageCode)}${window.location.hash}`
    );
  }, []);

  function getLocaleUri(localeLanguageCode: string): string | undefined {
    const currentLocale = `${localeLanguageCode}-${countryCode}`;

    const preferredLocale = alternateLocaleUris.find(
      (alternateLocale) => alternateLocale.locale === currentLocale
    );
    // Matching on only the language is not great, but the available data
    // makes this necessary in some circumstances.
    //
    // For example for NL marketplace, the alternateLocaleUris have 'locale's
    // of 'nl' and 'en-NL'. The 'nl' one represents 'nl-NL'.
    const fallbackLocale = alternateLocaleUris.find(
      (alternateLocale) => alternateLocale.locale === localeLanguageCode
    );
    // Favour a full match, on language and country.
    const locale = preferredLocale || fallbackLocale;

    return locale && locale.uri;
  }

  function alternateLanguageLinks(
    languages: LanguageSelectLinks[]
  ): React.ReactNode {
    return languages
      .filter((lang) => lang.uri)
      .map((lang) => (
        <a key={lang.code} href={lang.uri} lang={lang.code}>
          {lang.displayName}
        </a>
      ));
  }

  const languages = availableLanguages
    .map((language: Language) => ({
      ...language,
      value: language.code,
      label: language.displayName,
      uri: getLocaleUri(language.code),
    }))
    .filter((language) => language.uri);

  if (languages.length < 2) {
    return null;
  }

  const style = {
    '--Dropdown-item-padding': 'var(--spacing-xxs)',
  } as React.CSSProperties;

  return (
    <div className={styles.selector} style={style}>
      <Dropdown
        labelId="language"
        initialSelected={languageCode}
        caretSize={16}
        menuWidthAuto
        onSelected={onSelected}
      >
        {languages.map((language) => (
          <DropdownItem key={language.code} value={language.code}>
            <LanguageItem language={language} />
          </DropdownItem>
        ))}
      </Dropdown>

      {alternateLanguageLinks(languages)}
    </div>
  );
}

function LanguageItem({
  language,
  isDropdownOption,
}: {
  language: Language;
  isDropdownOption?: boolean;
}): React.ReactElement {
  const text = isDropdownOption ? language.displayName : language.code;
  const type = isDropdownOption ? 'body' : 'footnote';
  const style: React.CSSProperties = {
    textTransform: isDropdownOption ? 'none' : 'uppercase',
  };

  return (
    <div className={styles.item}>
      <Text type={type} style={style}>
        {text}
      </Text>
    </div>
  );
}
