import { isBrowser } from 'js/helpers/environment';
import { ReduxState } from 'js/model/state';
import { CreateReviewState } from 'js/model/state/createReview';
import {
  CreateReviewUserState,
  isCreateReviewUserState,
} from 'js/model/state/CreateReviewUserState';
import {
  CreateReviewActionType,
  createReviewReplaceUserState,
} from 'js/redux-modules/create-review/actions';
import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import debounce from 'lodash.debounce';
import { storage } from '@treatwell/ui';

type MiddlewareStoreState = Pick<ReduxState, 'createReview'>;

export const createReviewPersistUnfinishedMiddleware: Middleware<
  {},
  MiddlewareStoreState
> = (api) => {
  if (!isBrowser) {
    // This middleware should only be run in the browser
    // Returning a stub that does nothing on the server
    return stubMiddleware;
  }

  return reviewPersistUnfinishedMiddleware(api);
};

const stubMiddleware = (next: Dispatch) => (action: Action) => next(action);

let lastUserState: unknown;

const reviewPersistUnfinishedMiddleware =
  (api: MiddlewareAPI<Dispatch, MiddlewareStoreState>) =>
  (next: Dispatch) =>
  (action: Action) => {
    let forwardAction = action;

    const referenceToken = api.getState().createReview.data.referenceToken;
    let doWrite = true;

    if (
      action.type === CreateReviewActionType.RestoreUserStateFromLocalStorage
    ) {
      const userState = readUserReviewStateFromLocalStorage(referenceToken);
      if (userState) {
        debouncedWriteUserReviewStateToLocalStorage.cancel();
        forwardAction = createReviewReplaceUserState(userState);
        doWrite = false;
      }
    } else if (action.type === CreateReviewActionType.RequestSuccess) {
      debouncedWriteUserReviewStateToLocalStorage.cancel();
      deleteUserReviewFromLocalStorage(referenceToken);
      doWrite = false;
    }

    // Call the next dispatch method in the middleware chain.
    const returnAction = next(forwardAction);

    // State after dispatch
    const state = api.getState();
    if (
      doWrite &&
      lastUserState !== undefined &&
      state.createReview.user !== lastUserState &&
      state.createReview.data.referenceToken.length > 0
    ) {
      debouncedWriteUserReviewStateToLocalStorage(state.createReview);
    }

    lastUserState = state.createReview.user;

    return returnAction;
  };

const debouncedWriteUserReviewStateToLocalStorage = debounce(
  writeUserReviewStateToLocalStorage,
  250
);

function writeUserReviewStateToLocalStorage(state: CreateReviewState): void {
  const dataStr = JSON.stringify(state.user);
  storage.local.setItem(localStorageKey(state.data.referenceToken), dataStr);
}

function readUserReviewStateFromLocalStorage(
  referenceToken: string
): CreateReviewUserState | undefined {
  const localStorageValue = storage.local.getItem(
    localStorageKey(referenceToken)
  );

  if (!localStorageValue) {
    return undefined;
  }

  try {
    if (!isCreateReviewUserState(localStorageValue)) {
      return undefined;
    }

    return localStorageValue;
  } catch (error) {
    return undefined;
  }
}

function deleteUserReviewFromLocalStorage(referenceToken: string): void {
  storage.local.removeItem(localStorageKey(referenceToken));
}

function localStorageKey(referenceToken: string): string {
  return `create-review-${referenceToken}`;
}
