import React from 'react';
import { postNewsletterSubscribe } from 'js/service/newsletterSubscribeService';
import { RequestHeaders } from 'js/common/http';
// @ts-expect-error
import NewsletterSubscribe from './legacy/NewsletterSubscribe';
// @ts-expect-error
import NewsletterSubscribePopUp from './legacy/NewsletterSubscribePopup';
import * as track from './tracking';

const CHANNEL_CODE_IRELAND = 'WHN_IE';

interface Props {
  requestHeaders: RequestHeaders;
  onClose: () => void;
  i18n: (key: string, count?: number | string, ...args: string[]) => string;
  generateUri: (
    pageType: string,
    values: { type: string }
  ) => string | undefined;
  channelCode: string;
}

export function NewsletterPopup(props: Props): React.ReactElement {
  const [activeView, setActiveView] = React.useState(
    NewsletterSubscribe.EnumView.signup
  );
  React.useEffect(() => {
    track.newsletterPopupOpen();
  }, []);

  React.useEffect(() => {
    switch (activeView) {
      case NewsletterSubscribe.EnumView.success:
        track.newsletterPopupSuccess();
        break;
      case NewsletterSubscribe.EnumView.failure:
        track.newsletterPopupFailure();
    }
  }, [activeView]);

  function handleNewsletterSubmit(data: React.FormEvent): void {
    postNewsletterSubscribe(props.requestHeaders, data)
      .then(() => {
        setActiveView(NewsletterSubscribe.EnumView.success);
      })
      .catch(() => {
        setActiveView(NewsletterSubscribe.EnumView.failure);
      });
  }

  async function onClose(): Promise<void> {
    await track.newsletterPopupClose();
    return props.onClose();
  }

  const { i18n, generateUri, channelCode } = props;
  const cmsColors = 'common.newsletter-subscribe-popup.colors';

  return (
    <NewsletterSubscribePopUp
      activeView={activeView}
      textColor={i18n(`${cmsColors}.text`)}
      controlsForegroundColor={i18n(`${cmsColors}.controls-foreground-color`)}
      controlsBackgroundColor={i18n(`${cmsColors}.controls-background-color`)}
      showPostcodeInput={channelCode !== CHANNEL_CODE_IRELAND}
      privacyUri={generateUri('info', { type: 'privacy-policy' })}
      onSubmit={handleNewsletterSubmit}
      i18n={i18n}
      onClose={onClose}
    />
  );
}
