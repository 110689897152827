import { assertExhausted } from 'js/types/assert-unreachable';

export enum ReviewScreen {
  PublicReview = 'publicReview',
  Completion = 'completion',
  Venue = 'venue',
  Treatment = 'treatment',
  Employee = 'employee',
  AlreadyReviewed = 'alreadyReviewed',
}

export function isReviewScreen(candidate: unknown): candidate is ReviewScreen {
  const assertCandidate = candidate as ReviewScreen;
  switch (assertCandidate) {
    case ReviewScreen.PublicReview:
    case ReviewScreen.Completion:
    case ReviewScreen.Venue:
    case ReviewScreen.Treatment:
    case ReviewScreen.Employee:
    case ReviewScreen.AlreadyReviewed:
      return true;
    default:
      assertExhausted(assertCandidate);
      return false;
  }
}

export function getReviewScreenOrder(
  numTreatmentCategories: number,
  thereAreNoEmployees: boolean
): ReviewScreen[] {
  let reviewScreenOrder = [
    ReviewScreen.Venue,
    ReviewScreen.Treatment,
    ReviewScreen.Employee,
    ReviewScreen.PublicReview,
    ReviewScreen.Completion,
  ];

  if (numTreatmentCategories <= 0) {
    reviewScreenOrder = reviewScreenOrder.filter(
      (screen) => screen !== ReviewScreen.Treatment
    );
  }

  if (thereAreNoEmployees) {
    reviewScreenOrder = reviewScreenOrder.filter(
      (screen) => screen !== ReviewScreen.Employee
    );
  }

  return reviewScreenOrder;
}
