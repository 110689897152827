import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

export interface ThunkActionFunctions {
  request?: () => { type: string };
  success: <ApiResponseType>(response: ApiResponseType) => {
    type: string;
    response: ApiResponseType;
  };
  failure: <ErrorType>(error: ErrorType) => { type: string; error: ErrorType };
}

export type ThunkRequest = ThunkAction<Promise<unknown>, {}, unknown, Action>;

export function thunkRequest<APIResponse>(
  requestFn: () => Promise<APIResponse>,
  actionFns: ThunkActionFunctions
): ThunkRequest {
  return async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
    if (actionFns.request) {
      dispatch(actionFns.request());
    }
    try {
      const data: APIResponse = await requestFn();
      return dispatch(actionFns.success(data));
    } catch (error) {
      return dispatch(actionFns.failure(error));
    }
  };
}
