import { combineReducers } from 'redux';
import { venueMenu } from './venue-menu';
import { servicePopup } from './popup';
import { selectedMenuGroups } from './menu-groups';

export { default as initVenuePage } from './initial';

export default combineReducers({
  servicePopup,
  venueMenu,
  selectedMenuGroups,
});

export const getVenuePage = (state) => state.venuePage || {};
export const getSelectedMenuGroups = (state) =>
  getVenuePage(state).selectedMenuGroups || {};
export const getVenueMenu = (state) => getVenuePage(state).venueMenu || {};
