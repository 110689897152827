export const isServer: boolean =
  typeof process === 'object' &&
  typeof process.versions === 'object' &&
  typeof process.versions.node === 'string';

export const isBrowser: boolean =
  typeof window === 'object' && typeof navigator === 'object';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const state = typeof window === 'object' && (window.__state__ as any);
export const isProductionEnvironment =
  (isServer && process.env.TW_ENV_TYPE === 'prod') ||
  (isBrowser &&
    state &&
    state.environment &&
    state.environment.twEnvType === 'prod');

export const twEnv =
  (isServer && process.env.TW_ENV) ||
  (isBrowser && state && state.environment && state.environment.twEnv);

export const isCypress = process.env.CYPRESS === 'true';

export type TWEnvType = 'dev' | 'staging' | 'prod' | 'unknown';

export const twEnvTypeValue: TWEnvType =
  (isServer &&
    (process.env.TW_ENV_TYPE
      ? (process.env.TW_ENV_TYPE as TWEnvType)
      : 'unknown')) ||
  (isBrowser &&
    state &&
    state.environment &&
    (state.environment.twEnvType as TWEnvType));

export function isTest(): boolean {
  /*
   * isTest has to be a function as `global.isTest` wouldn't necessarily be set when
   * this module is initialized.
   */
  if (global.isTest === true) {
    return true;
  }

  if (
    typeof process === 'object' &&
    typeof process.env === 'object' &&
    process.env.NODE_ENV === 'test'
  ) {
    return true;
  }

  return false;
}

// Mobile Detection via User Agent Sniffing

export function isOSMobile(): boolean {
  return isIOS() || isAndroid() || isWindowsMobile();
}

interface MSWindow extends Window {
  MSStream: unknown;
}

export function isIOS(): boolean {
  // no case sensitive check, as Apple would never make a spelling error :)
  // also probably safer against impostor browsers. see https://stackoverflow.com/a/9039885/3650874
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !(window as unknown as MSWindow).MSStream
  );
}

export function isAndroid(): boolean {
  return /Android/i.test(navigator.userAgent);
}

export function isWindowsMobile(): boolean {
  return /IEMobile/i.test(navigator.userAgent);
}
