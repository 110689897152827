import {useState, useEffect, useCallback, RefObject} from 'react';

export const useKeyPress = ({
  key,
  element,
  onPress,
}: {
  key: string;
  element?: RefObject<HTMLElement | null>;
  onPress?: (event: KeyboardEvent, type: 'KEYUP' | 'KEYDOWN') => void;
}) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        setKeyPressed(true);
        if (onPress) {
          onPress(event, 'KEYDOWN');
        }
      }
    },
    [key, onPress]
  );

  const upHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        setKeyPressed(false);
        if (onPress) {
          onPress(event, 'KEYUP');
        }
      }
    },
    [key, onPress]
  );

  useEffect(() => {
    const targetElem = element?.current ? element.current : document;
    targetElem.addEventListener('keydown', downHandler as EventListener);
    targetElem.addEventListener('keyup', upHandler as EventListener);

    return () => {
      targetElem.removeEventListener('keydown', downHandler as EventListener);
      targetElem.removeEventListener('keyup', upHandler as EventListener);
    };
  }, [downHandler, upHandler, element]);

  return keyPressed;
};
