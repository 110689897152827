import hash from 'hash.js';
import {
  AccountDetailsApiResponse,
  LoginStatus,
} from 'js/model/rainbow/AccountDetailsOutput';
import {
  AccountDetailsState,
  UserDataHashes,
} from 'js/model/state/account/details';
import {
  AccountDetailsAction,
  ACCOUNT_DETAILS_REQUEST,
  ACCOUNT_DETAILS_SUCCESS,
  ACCOUNT_DETAILS_FAILURE,
} from './actions';

const AUTHENTICATED_STATUS = [
  LoginStatus.profileAuthenticated,
  LoginStatus.accountAuthenticated,
];

function getFacebookAccountValue(val?: string): string | null {
  return (val && val.toLowerCase().replace(/\s/g, '')) || null;
}

function getUserDataHashes(account: AccountDetailsApiResponse): UserDataHashes {
  const mappings = {
    em: getFacebookAccountValue(account.email),
    fn: getFacebookAccountValue(account.firstName),
    ln: getFacebookAccountValue(account.lastName),
    ge:
      account && account.gender ? account.gender.charAt(0).toLowerCase() : null,
    ph:
      account && account.intlPhoneNumber
        ? account.intlPhoneNumber.replace(/\s|\+/g, '')
        : null,
  };

  const keys = Object.keys(mappings) as (keyof typeof mappings)[];
  const hashes = keys.reduce((acc: Partial<UserDataHashes>, key) => {
    const value: string | null = mappings[key];
    if (value) {
      acc[key] = hash.sha256().update(value).digest('hex');
    }
    return acc;
  }, {});

  return hashes as UserDataHashes;
}

export function details(
  state: AccountDetailsState = {},
  action: AccountDetailsAction
): AccountDetailsState {
  switch (action.type) {
    case ACCOUNT_DETAILS_REQUEST:
      return { isFetching: true };
    case ACCOUNT_DETAILS_FAILURE:
      return {
        isFetching: false,
        user: {
          isAuthenticated: false,
          isConnectAccount: false,
          authenticationState: LoginStatus.notRecognised,
        },
      };
    case ACCOUNT_DETAILS_SUCCESS: {
      if (!action.response) {
        return { isFetching: false };
      }
      const response = action.response;
      const loginStatus = response.loginStatus.toLowerCase() as LoginStatus;
      const isAuthenticated = AUTHENTICATED_STATUS.includes(loginStatus);
      const user = {
        accountId: response.accountId,
        isAuthenticated,
        isConnectAccount: response.connectAccountAssociated,
        authenticationState: loginStatus,
        hashes: isAuthenticated ? getUserDataHashes(response) : undefined,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        phone: response.intlPhoneNumber,
      };
      return { isFetching: false, user };
    }
    default:
      return state;
  }
}
