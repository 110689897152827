const SELECT_MENU_OPTION = 'venue-page/venue-menu/SELECT_MENU_OPTION';
const UNSELECT_MENU_OPTION = 'venue-page/venue-menu/UNSELECT_MENU_OPTION';
const TOGGLE_VENUE_MENU_HIGHLIGHTED_SERVICE =
  'venue-page/venue-menu/TOGGLE_VENUE_MENU_HIGHLIGHTED_SERVICE';
const TOGGLE_VENUE_MENU_SERVICE =
  'venue-page/venue-menu/TOGGLE_VENUE_MENU_SERVICE';
const SET_ACTIVE_TREATMENT_TYPE =
  'venue-page/venue-menu/SET_ACTIVE_TREATMENT_TYPE';
const UPDATE_TREATMENT_TYPE_MENU_GROUP_SELECTED =
  'venue-page/venue-menu/UPDATE_TREATMENT_TYPE_MENU_GROUP_SELECTED';

export const selectMenuOption = (
  groupId,
  serviceId,
  optionId,
  treatmentTypeId
) => ({
  type: SELECT_MENU_OPTION,
  groupId,
  serviceId,
  optionId,
  treatmentTypeId,
});

export const unselectMenuOption = (
  groupId,
  serviceId,
  optionId,
  treatmentTypeId
) => ({
  type: UNSELECT_MENU_OPTION,
  groupId,
  serviceId,
  optionId,
  treatmentTypeId,
});

export const toggleVenueMenuHighlightedService = (id, expanded) => ({
  type: TOGGLE_VENUE_MENU_HIGHLIGHTED_SERVICE,
  expanded,
  id,
});

export const toggleVenueMenuService = (id, expanded) => ({
  type: TOGGLE_VENUE_MENU_SERVICE,
  expanded,
  id,
});

export const setActiveTreatmentType = (
  treatmentTypeId,
  filteredMenuGroups,
  selected
) => ({
  type: SET_ACTIVE_TREATMENT_TYPE,
  treatmentTypeId,
  filteredMenuGroups,
  selected,
});

export const updateTreatmentTypeMenuGroupSelected = (menuGroupId) => ({
  type: UPDATE_TREATMENT_TYPE_MENU_GROUP_SELECTED,
  menuGroupId,
});

const unselectOption = (
  state,
  { groupId, serviceId, optionId, treatmentTypeId }
) => {
  const { selected } = state;
  const group = selected[groupId] || {};
  const services = group[serviceId] || {};
  const newServices = {
    ...services,
  };

  delete newServices[optionId];

  const newGroup = {
    ...group,
    [serviceId]: newServices,
  };

  if (Object.keys(newServices).length === 0) {
    delete newGroup[serviceId];
  }

  const newSelected = {
    ...state.selected,
    [groupId]: newGroup,
  };

  if (Object.keys(newSelected[groupId]).length === 0) {
    delete newSelected[groupId];
  }
  const newTreatmentTypes = [...state.selectedTreatmentTypes];

  newTreatmentTypes.splice(newTreatmentTypes.indexOf(treatmentTypeId), 1);

  return {
    ...state,
    selected: newSelected,
    selectedSkuIds: state.selectedSkuIds.filter((id) => id !== optionId),
    selectedTreatmentTypes: newTreatmentTypes,
  };
};

const selectOption = (
  state,
  { groupId, serviceId, optionId, treatmentTypeId }
) => {
  const { selected } = state;
  const group = (selected && selected[groupId]) || {};
  const services = group[serviceId] || {};
  const selectedSkuIds = state.selectedSkuIds || [];
  const selectedTreatmentTypes = state.selectedTreatmentTypes || [];

  if (group && services && selectedSkuIds.includes(optionId)) {
    // The option is already selected.
    return state;
  }

  return {
    ...state,
    selected: {
      ...state.selected,
      [groupId]: {
        ...group,
        [serviceId]: {
          ...services,
          [optionId]: true,
        },
      },
    },
    selectedSkuIds: selectedSkuIds.concat([optionId]),
    selectedTreatmentTypes: selectedTreatmentTypes.concat([treatmentTypeId]),
  };
};

export const venueMenu = (state, action = {}) => {
  if (!state) {
    return {
      selected: {},
      highlightedExpanded: {},
      expanded: {},
      selectedSkuIds: [],
      selectedTreatmentTypes: [],
      filteredMenuGroups: {},
    };
  }

  switch (action.type) {
    case TOGGLE_VENUE_MENU_HIGHLIGHTED_SERVICE:
      return {
        ...state,
        highlightedExpanded: {
          ...state.highlightedExpanded,
          [action.id]: action.expanded,
        },
      };
    case TOGGLE_VENUE_MENU_SERVICE:
      return {
        ...state,
        expanded: {
          ...state.expanded,
          [action.id]: action.expanded,
        },
      };
    case SELECT_MENU_OPTION:
      return selectOption(state, action);
    case UNSELECT_MENU_OPTION:
      return unselectOption(state, action);
    case SET_ACTIVE_TREATMENT_TYPE:
      return {
        ...state,
        activeTreatmentTypeId: action.treatmentTypeId,
        filteredMenuGroups: {
          ...state.filteredMenuGroups,
          [action.treatmentTypeId]: {
            groups: [...action.filteredMenuGroups],
            selected: action.selected,
          },
        },
      };
    case UPDATE_TREATMENT_TYPE_MENU_GROUP_SELECTED: {
      const filteredMenuGroups = state.filteredMenuGroups;
      const group = state.filteredMenuGroups[state.activeTreatmentTypeId];

      return {
        ...state,
        filteredMenuGroups: {
          ...filteredMenuGroups,
          [state.activeTreatmentTypeId]: {
            ...group,
            selected: action.menuGroupId,
          },
        },
      };
    }
    default:
      return state;
  }
};
