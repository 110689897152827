import React from 'react';
import { generateRuid } from 'js/helpers/ruid';
import { httpPost, RequestHeaders } from 'js/common/http';

export const postNewsletterSubscribe = (
  requestHeaders: RequestHeaders,
  data: React.FormEvent,
  ruid = generateRuid()
): Promise<{ response: unknown } | string> => {
  const uri = '/api/v1/newsletter-subscription';

  return httpPost(uri, { ruid, requestHeaders, data }).then((response) => ({
    response,
  }));
};
