import React, { useRef } from 'react';
import { browsePathLocationFragment } from 'js/components/Navigation/navigation-location';
import { useIsMounted } from 'js/hooks/useIsMounted';
import styles from './Navigation.module.css';
import {
  Action,
  TrackSubNavLinkSelect,
  trackToggleNavigationTab,
} from './tracking';
import { NavigationTab } from './NavigationTab';
import { NavigationDropdown } from './NavigationDropdown';
import { PageOverlay } from './PageOverlay';
import { Promo } from './Promotion';

export type Tabs = {
  'child-items': {
    enabled: string;
    label: string;
    'uri-template': string;
  }[];
  'child-items-enabled': string;
  enabled: string;
  label: string;
  promos?: Promo[];
  uri: string;
};

export type HeaderNavigation = {
  'location-or-postalref-template': string;
  'to-days-offset': string;
  'url-postal-location-templates': {
    postal: string;
    common: string;
    location: string;
  };
  'profile-location-uri-patterns': {
    'pattern-3-persisted-location': string;
    'pattern-2-persisted-location': string;
    'pattern-3-locations': string;
    'pattern-1-locations': string;
    'pattern-1-persisted-location': string;
    'pattern-2-locations': string;
    pattern: string[];
  };
  'from-days-offset': string;
  tabs: Tabs[];
};

interface Props {
  headerNavigation: HeaderNavigation;
  countryNormalisedName: string;
  onSubNavOpened?: () => void;
  languageCode: string;
}

export function Navigation(props: Props): React.ReactElement {
  const activeTabTextRef = useRef<string | null>(null);
  const [selectedTab, setSelectedTab] = React.useState<number | null>(null);
  const [isOverlayVisible, setIsOverlayVisible] =
    React.useState<boolean>(false);
  const isBrowser = useIsMounted();
  const generateNavigationTabs = (uriLocationReference: string) => {
    const tabs = props.headerNavigation.tabs;
    let merchSlotCounter = 1;

    return tabs.map((tab, index) => {
      let merchSlotCount;
      if (!tab['child-items'].length) {
        merchSlotCount = merchSlotCounter;
        merchSlotCounter++;
      }

      return (
        <NavigationTab
          key={index}
          selectedTab={selectedTab}
          index={index}
          tab={tab}
          uriLocationReference={uriLocationReference}
          merchSlotNumber={merchSlotCount}
          toggleNavigationTab={handleNavigationTabChange}
        />
      );
    });
  };

  const generateDefaultLocationReference = (
    locationName: string,
    countryNormalisedName: string
  ): string => {
    return countryNormalisedName
      ? `${locationName}-${countryNormalisedName}`
      : '';
  };

  const generateLocationReference = (
    locationName: string,
    postal: string
  ): string | undefined => {
    return browsePathLocationFragment(locationName, postal, props.languageCode);
  };

  const handleClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
  };

  const trackActiveTab = (newTabText: string | null): void => {
    if (activeTabTextRef.current !== null) {
      trackToggleNavigationTab(activeTabTextRef.current, Action.Collapse);
    }

    // set activeTabText to new value, unless it hasn't changed,
    // which indicates closure of nav by clicking active tab for second time.
    activeTabTextRef.current =
      activeTabTextRef.current !== newTabText ? newTabText : null;

    if (activeTabTextRef.current !== null) {
      trackToggleNavigationTab(activeTabTextRef.current, Action.Expand);
    }
  };

  const handleSubNavLinkClick = (
    linkLabel: string,
    linkIndex: number,
    redirectUri: string
  ) => {
    if (!activeTabTextRef.current) {
      return;
    }

    TrackSubNavLinkSelect(activeTabTextRef.current, linkLabel, linkIndex);

    window.location.href = redirectUri;
  };

  const handleNavigationTabChange: (
    selectedTabIndex: number | null,
    newSelectedTabIndex: number | null,
    newSelectedTabLabel: string | null
  ) => void = (
    selectedTabIndex,
    newSelectedTabIndex,
    newSelectedTabLabel
  ): void => {
    // if selectedTabIndex then nav is open
    const subNavIsVisible = selectedTabIndex !== null;

    const tabIsAlreadySelected = newSelectedTabIndex === selectedTabIndex;
    let newSelection = null;

    if (subNavIsVisible) {
      if (!tabIsAlreadySelected) {
        newSelection = newSelectedTabIndex;
      }
    } else {
      newSelection = newSelectedTabIndex;

      if (props.onSubNavOpened) {
        props.onSubNavOpened();
      }
    }

    // hide overlay if no selected tab, or if we are closing nav by clicking on selected
    const shouldShowOverlay = !(
      newSelectedTabIndex === null || newSelectedTabIndex === selectedTabIndex
    );

    setSelectedTab(newSelection);
    setIsOverlayVisible(shouldShowOverlay);

    trackActiveTab(newSelectedTabLabel);
  };

  const handleNavigationDismiss = (): void => {
    setSelectedTab(null);
    setIsOverlayVisible(false);
    trackActiveTab(null);
  };

  const { headerNavigation, countryNormalisedName } = props;
  const tabs = headerNavigation.tabs;
  const locationName =
    headerNavigation['url-postal-location-templates'].location;
  const postal = headerNavigation['url-postal-location-templates'].postal;

  // We only add persisted location information to the URL when the
  // menu has been opened, this stops local differences from conflicting
  // with the initial server-side render
  const defaultLocationReference = generateDefaultLocationReference(
    locationName,
    countryNormalisedName
  );

  let uriLocationReference = defaultLocationReference;

  if (isBrowser) {
    const locationReference = generateLocationReference(locationName, postal);
    uriLocationReference = isOverlayVisible
      ? locationReference || defaultLocationReference
      : defaultLocationReference;
  }

  const navigationTabs = generateNavigationTabs(uriLocationReference);

  return (
    <div className={styles.navigation} onClick={handleClick}>
      <div className={styles.navTabs}>
        <div className={styles.navTabsWrapper}>{navigationTabs}</div>
      </div>
      <NavigationDropdown
        tabs={tabs}
        selected={selectedTab}
        uriLocationReference={uriLocationReference}
        toggleNavigationTab={handleNavigationTabChange}
        onSubNavLinkClick={handleSubNavLinkClick}
      />
      {isOverlayVisible && <PageOverlay onDismiss={handleNavigationDismiss} />}
    </div>
  );
}
