import React from 'react';
import clsx from 'clsx';
import styles from './CloseButton.module.css';

export default function CloseButton({ color, positioningClassName, onClick }) {
  return (
    <svg
      className={clsx(styles.closeButton, positioningClassName)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path stroke={color} strokeWidth="2" d="M18 18L6 6M6 18L18 6 6 18z" />
      </g>
    </svg>
  );
}
