import { Channel } from 'js/model/rainbow/content/ChannelOutput';

export const isLanguageCodeRequired = (channel: Channel) => {
  return (
    channel.languageCode &&
    channel.availableLanguages.find(
      (language) =>
        language.code === channel.languageCode &&
        // on old stack pages we do not have the "primary" property
        language.primary !== undefined &&
        !language.primary
    )
  );
};
