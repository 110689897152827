import movingAverage from 'moving-average';

const second = 1000;
const minute = 60 * second;
const errorRatePushPeriod = 5 * second;

export const errorRates = [1, 5].map((minutes) => ({
  period: minutes,

  requestCount: 0,
  requestAverage: movingAverage(minutes * minute),

  errorCount: 0,
  errorAverage: movingAverage(minutes * minute),
}));

export function initialiseErrorRates(): void {
  setInterval(() => {
    errorRates.forEach((errorRate) => {
      errorRate.requestAverage.push(Date.now(), errorRate.requestCount);
      errorRate.errorAverage.push(Date.now(), errorRate.errorCount);

      errorRate.requestCount = 0;
      errorRate.errorCount = 0;
    });
  }, errorRatePushPeriod);
}

export function trackErrorRate(isError: boolean): void {
  errorRates.forEach((errorRate) => {
    errorRate.requestCount++;

    if (isError) {
      errorRate.errorCount++;
    }
  });
}
