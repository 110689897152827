// This function returns a promise allowing the use of the eventCallback
// function in datalayer to ensure the data is sent to Google Analytics
// before continuing.
// Useful when an action triggers navigation
// The Promise is resolved when eventCallback returns or eventTimeout expires.
export const track = (event, data, eventTimeout = 500) =>
  new Promise((resolve) => {
    // AdBlock stops our datalayer callback from working, make sure we still
    // resolve.
    const timeout = window.setTimeout(resolve, eventTimeout);

    push({
      event,
      ...data,
      eventTimeout,
      eventCallback: () => {
        window.clearTimeout(timeout);
        resolve();
      },
    });
  });

export function push(payload) {
  let dataLayer = window.dataLayer1;
  let num = 1;

  while (dataLayer) {
    dataLayer.push(payload);

    num += 1;
    dataLayer = window[`dataLayer${num}`];
  }
}

export const trackEvent = (event, data, eventTimeout) =>
  track(event, data, eventTimeout);

export const trackStructuredEvent = (data, eventTimeout) => {
  const tempData = { ...data };

  if (tempData.value === undefined) {
    tempData.value = undefined;
  }
  if (tempData.label === undefined) {
    tempData.label = undefined;
  }
  if (tempData.property === undefined) {
    tempData.property = undefined;
  }
  return track('trackEvent', { eventData: tempData }, eventTimeout);
};
