/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import 'assets/style/grid.module.css';
import 'assets/style/fonts.module.css';
import 'assets/style/animate.module.css';
import { LocaleWrapper } from 'js/components/LocaleWrapper';
import HeaderWrapper from 'js/components/Header/HeaderWrapper';
import { Footer } from 'js/components/Footer';
import { createOptionalHighPriorityMessagingBanner } from 'js/components/HighPriorityMessagingBanner/createOptionalHighPriorityMessagingBanner';
import Routes from 'js/pages';
import { channelContext } from 'js/components/channel';
import { featureFlagsContext } from 'js/components/featureFlagsContext';
import { getPageType, getIsWidget } from 'js/helpers/page-data';
import { isBrowser } from 'js/helpers/environment';
import { hasChannelFeature, feature } from 'js/helpers/channel-feature';
import { LoyaltyBanner } from './components/Loyalty/LoyaltyBanner';
import { StateData } from './model/rainbow/StateData';
import { VenuePageHeader } from './components/Header/VenuePageHeader/VenuePageHeader';
import SimpleHeaderContainer from './components/Header/SimpleHeader/SimpleHeaderContainer';

type RootProps = StateData;

class Root extends React.Component<{ pageData: RootProps }> {
  getDatalayer = () =>
    isBrowser ? window.dataLayer1 || window.dataLayer : null;

  render() {
    const { pageData } = this.props;
    const {
      channel,
      cms,
      languageCode,
      showHeaderAndFooter,
      showSimpleHeader,
    } = pageData;
    const pageType = getPageType(pageData);
    const isWidget = getIsWidget(pageData);
    const showVenuePageHeader = pageType === 'venue';
    const showExperimentVenuePageHeader = pageData.showVenueHeaderExperiment;

    let children = [
      <Routes key="routes" isWidget={isWidget} pageType={pageType} />,
    ];

    if (!isWidget && showHeaderAndFooter) {
      const header =
        // eslint-disable-next-line no-nested-ternary
        showSimpleHeader || showExperimentVenuePageHeader ? (
          <SimpleHeaderContainer
            showExperimentVenuePageHeader={showExperimentVenuePageHeader}
          />
        ) : showVenuePageHeader ? (
          <VenuePageHeader pageType={pageType} key="venue-page-header" />
        ) : (
          <HeaderWrapper key="header" pageType={pageType} />
        );

      const footer = (
        <Footer
          key="footer"
          channelCode={channel.code}
          ruid={pageData.ruid}
          marketplaces={pageData.marketplaces}
          showCookieSettingsLinks={hasChannelFeature(
            channel.channelFeatures,
            feature.ONETRUST_COOKIE_BANNER
          )}
        />
      );
      children = [header, ...children, footer];

      if (pageType === 'homepage') {
        const loyaltyBanner = <LoyaltyBanner key="loyalty-banner" cms={cms} />;
        children.unshift(loyaltyBanner);
      }
    }

    const optionalHighPriorityMessagingBanner =
      createOptionalHighPriorityMessagingBanner(cms);

    return (
      <LocaleWrapper
        channel={channel}
        languageCode={languageCode}
        cms={cms}
        locale={channel.locale}
        pageData={pageData}
      >
        <channelContext.Provider value={channel}>
          <featureFlagsContext.Provider value={pageData.featureFlags}>
            {children}
            {optionalHighPriorityMessagingBanner}
          </featureFlagsContext.Provider>
        </channelContext.Provider>
      </LocaleWrapper>
    );
  }
}

export default Root;
