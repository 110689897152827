import format from 'date-fns/format';
import { ItemResultData } from 'js/components/controls/search-item-result';
import { isBrowser } from 'js/helpers/environment';
import { fetchCached, RequestData } from 'js/helpers/service';
import { ExternalLocationOutput } from 'js/model/rainbow/ExternalLocationOutput';
import { parse } from 'qs';
import { BROWSE_URI_DATE_FORMAT } from './datetime-format';

interface ParsedUri {
  pageType: string;
  values: { [index: string]: string[] };
}

export interface RawParsedUri extends ParsedUri {
  errorMessage?: string;
}

interface GeneratedUri {
  errorMessage?: string;
  uri?: string;
}

// The functions available in window.UriUtil, after src/js/service/javascriptService.js
// executes javascript files from rainbow.
export interface UriUtil {
  extractLanguageCodeFromPath: (path: string, channelCode?: string) => string;

  generateUri: (
    pageType: string,
    values: { [index: string]: string[] },
    channelCode: string,
    languageCode: string
  ) => GeneratedUri;

  parseUri: (path: string, compositeKey: string) => RawParsedUri;
}

function uriUtil(): UriUtil {
  return global.com.treatwell.common.uriutilsjsweet.MarketplaceUriUtil;
}

export function setUriUtil(uriUtil: UriUtil): void {
  global.com = {
    treatwell: {
      common: {
        uriutilsjsweet: {
          MarketplaceUriUtil: uriUtil,
        },
      },
    },
  };
}

/**
 * queryToObj
 *
 * This function converts a queryString and/or hash into an Object.
 * It prioritises the end of a string over the beginning (due to pattern using "g")
 * so if the same variable is present in the query string and the hash
 * the value in the has will be the value returned.
 *
 * the params that are passed to the function are specific to the pattern
 * "sym" will be a ? # or &
 * "key" is our variable name e.g. var1 in the below example
 * "eq" is the = symbol
 * "val" is our variable value e.g. "abc" in the below example
 *
 * @param {String} str the query string / hash to decode
 * @return {Object} the decoded object of params
 *
 * @example
 *  queryToObj('?var1=abc&var2=efg#var3=123&var4=456')
 * @returns {Object} {
 *  var1: "abc",
 *  var2: "efg"
 *  var3: "123"
 *  var4: "456"
 * }
 */
export function queryToObj(str: string): { [index: string]: string } {
  const obj: { [index: string]: string } = {};
  str.replace(
    new RegExp('([^?#=&]+)(=([^&#]*))?', 'g'),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (sym, ...[key, eq, val]) => {
      obj[key] = decodeURIComponent(val);
      return '';
    }
  );
  return obj;
}
/**
 * objToQuery
 *
 * This function converts an Object into a query string
 * by looping through the keys of the object and creating
 * an array of strings like "var1=abc" which then are joined
 * with the & to create the full string.
 *
 * if a key's value is set to undefined it will not be included
 * the values are url encoded except for the comma character
 *
 * @param {Object} obj the object to be converted to a query string
 * @param {String} start a prefix to the returned string
 *
 * @example
 * objToQuery({
 *  var1: "abc",
 *  var2: "efg"
 *  var3: "123"
 *  var4: "456"
 * }, "#")
 * @returns {String} #var1=abc&var2=efg#var3=123&var4=456
 */
export function objToQuery(
  obj: { [index: string]: string },
  start = '?'
): string {
  return (
    start +
    Object.keys(obj)
      .reduce((array: string[], key) => {
        if (obj[key] !== undefined) {
          array.push(
            `${key}=${encodeURIComponent(obj[key]).replace(/%2C/g, ',')}`
          );
        }
        return array;
      }, [])
      .join('&')
  );
}

export function formatBrowseDateParameter(browseDateParameter: Date): string {
  return format(browseDateParameter, BROWSE_URI_DATE_FORMAT);
}

export function browseGeolocationValue(
  // eslint-disable-next-line no-undef
  geoCoords: GeolocationCoordinates
): { searchAreaGeocode: string } {
  return { searchAreaGeocode: `${geoCoords.latitude},${geoCoords.longitude}` };
}

export async function browseLocationValue(
  state: RequestData,
  locationSearchData: ItemResultData | null
): Promise<{}> {
  if (!locationSearchData) {
    return {};
  }

  const TYPE_TO_URI_VALUE_KEY_MAP: { [index: string]: string } = {
    location: 'location',
    postal_area: 'location',
    postal_reference: 'postalReference',
    external_location: 'externalLocation',
  };

  if (
    !Object.prototype.hasOwnProperty.call(
      TYPE_TO_URI_VALUE_KEY_MAP,
      locationSearchData.entityType
    )
  ) {
    console.warn('unknown location type');
    return {};
  }

  let value;
  if (locationSearchData.entityType === 'external_location') {
    value = await fetchExternalLocationId(
      state,
      locationSearchData.entityValue,
      locationSearchData.name
    );
  } else {
    value = locationSearchData.entityValue;
  }

  const key = TYPE_TO_URI_VALUE_KEY_MAP[locationSearchData.entityType];
  return { [key]: value };
}

async function fetchExternalLocationId(
  state: RequestData,
  reference: string,
  name: string
): Promise<number | null> {
  const path =
    '/api/v1/search/external-location?' +
    `reference=${encodeURIComponent(reference)}&` +
    `description=${encodeURIComponent(name)}`;

  try {
    const data = await fetchCached<ExternalLocationOutput>(state, path);
    if (data === null) {
      return null;
    }

    if (typeof data.id !== 'number') {
      throw new TypeError(
        `malformed rainbow response from external-location: ${data}`
      );
    }

    return data.id;
  } catch (error) {
    console.warn(error);
    return null;
  }
}

export function availableOnValue(selectedDate: Date | null): {
  availableOn: string | null;
} {
  return {
    availableOn: selectedDate ? formatBrowseDateParameter(selectedDate) : null,
  };
}

export function parseUri(
  path: string,
  channelCode: string,
  languageCode: string
): ParsedUri | undefined {
  let cleanPath = path;
  const pathLanguageCode = uriUtil().extractLanguageCodeFromPath(cleanPath);
  if (pathLanguageCode) {
    cleanPath = path.replace(new RegExp(`^/${pathLanguageCode}`), '');
  }

  const uri = uriUtil().parseUri(
    cleanPath,
    `${channelCode}:${pathLanguageCode || languageCode}`
  );
  if (uri.errorMessage) {
    return undefined;
  }

  return uri as ParsedUri;
}

export function extractLanguageCodeFromPath(
  path: string,
  channelCode: string
): string {
  return uriUtil().extractLanguageCodeFromPath(path, channelCode);
}

export function generateUri(
  pageType: string,
  values: { [index: string]: string[] | string },
  channelCode: string,
  languageCode: string
): string | undefined {
  const arrayifiedValues: { [index: string]: string[] } = {};

  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      const value = values[key];

      if (Array.isArray(value)) {
        arrayifiedValues[key] = value;
      } else {
        arrayifiedValues[key] = [value];
      }
    }
  }

  const uri = uriUtil().generateUri(
    pageType,
    arrayifiedValues,
    channelCode,
    languageCode
  );
  if (uri.errorMessage) {
    console.warn(
      `Failed to generate uri for ${pageType}, with ${JSON.stringify(
        arrayifiedValues
      )} :${uri.errorMessage}`
    );
    return undefined;
  }

  return uri.uri;
}

export function getDomain(reqHeader?: string): string | undefined {
  if (reqHeader) {
    return reqHeader;
  }
  return isBrowser ? window.document.domain : undefined;
}

const invalidParamName = new RegExp('^[a-z0-9_-]*$', 'i');
export function getFragmentValue(name: string): string | undefined {
  if (!invalidParamName.test(name)) {
    throw new Error(`Invalid param name supplied: ${name}`);
  }

  if (!isBrowser) {
    return undefined;
  }
  const { search, hash } = window.location;
  const match = (search + hash).match(
    new RegExp(`!?.*([?&#]${name}=([^&#]*))`)
  );
  return (match && decodeURIComponent(match[2])) || undefined;
}

export function getFragmentMultiValue(name: string): string[] {
  const fragment = getFragmentValue(name);
  if (!fragment) {
    return [];
  }
  return fragment.split(',');
}

export function updateUriWithOpenPopup(objParams: {}): void {
  if (!isBrowser || typeof objParams !== 'object') {
    return;
  }
  const { pathname, hash, search } = window.location;
  const newParams = { ...queryToObj(hash), ...objParams };
  window.history.pushState(
    newParams,
    '',
    pathname + search + objToQuery(newParams, '#')
  );
}

// currentPage is 0-based but displayed in 1-based
export function previousPaginationPage(currentPage: number): number {
  return currentPage;
}

export function nextPaginationPage(currentPage: number): number {
  return currentPage + 2;
}

export function windowLocationQueryParameters(): { [key: string]: string } {
  let search = window.location.search;
  if (search.startsWith('?')) {
    search = search.substr(1);
  }

  return parse(search);
}

/**
 * Removes a specified parameter from the hash part of the URL.
 *
 * @param {Location} location - The Location object representing the current URL.
 * @param {string} parameter - The name of the parameter to be removed from the hash.
 *
 * This function takes the current URL's Location object and a parameter name as inputs.
 * It parses the hash of the URL, removes the specified parameter, and updates the browser's history with the new URL without reloading the page.
 *
 * @example
 * const location = window.location;
 * const parameterToRemove = 'portfolioImage';
 *
 * removeHashParameter(location, parameterToRemove);
 */
export const removeHashParameter = (location: Location, parameter: string) => {
  const { pathname, hash, search } = location;
  const newParams = { ...queryToObj(hash) };

  delete newParams[parameter];

  const url =
    Object.keys(newParams).length > 0
      ? pathname + search + objToQuery(newParams, '#')
      : pathname + search;
  const currentUrl = pathname + search + hash;

  if (currentUrl !== url) {
    window.history.pushState(newParams, '', url);
  }
};
