import React from 'react';

export default function AssetCheckbox({
  positioningClassName,
  isActive,
  primaryColor,
  secondaryColor,
}) {
  if (isActive) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={positioningClassName}
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <rect
            width="14"
            height="14"
            x="5"
            y="5"
            fill={primaryColor}
            stroke={primaryColor}
            strokeWidth="2"
            rx="1"
          />
          <path stroke={secondaryColor} strokeWidth="2" d="M7.5 12l3 3 6-6" />
        </g>
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={positioningClassName}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <rect
          width="14"
          height="14"
          x="5"
          y="5"
          stroke={primaryColor}
          strokeWidth="2"
          rx="1"
        />
      </g>
    </svg>
  );
}
