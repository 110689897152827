import {
  Icon,
  IconArrowBack,
  IconChevronLeft,
  Inline,
  MediaObject,
  Text,
} from '@treatwell/ui';
import React, { useEffect, MouseEvent } from 'react';
import { LanguageSelector } from 'js/components/LanguageSelector';
import { Context, i18nFunction } from 'js/components/LocaleWrapper';
import { getPageType } from 'js/helpers/page-data';
import { getAccountDetails } from 'js/redux-modules/account/selectors';
import { ReduxState } from 'js/model/state';
import { connect } from 'react-redux';
import { loadAccountDetails } from 'js/redux-modules/account/details/actions';
import { AccountDetailsState } from 'js/model/state/account/details';
import { useIsMounted } from 'js/hooks/useIsMounted';
import { getBreadcrumbs } from 'js/pages/VenuePage/BreadcrumbsSection/BreadcrumbsSection';
import { useTranslation } from 'react-i18next';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { StateData } from 'js/model/rainbow/StateData';
import { TFunction } from 'i18next';
import { HeaderLinks, Links } from '../HeaderLinks';
import { getHeaderLinks } from '../getHeaderLinks';
import styles from './VenuePageHeaderMobile.module.css';
import { VenuePageHeaderMobile } from './VenuePageHeaderMobile';
import { trackVenueHeaderExperimentBackClick } from './tracking';

interface Props {
  accountDetails: AccountDetailsState;
  loadAccountDetails: () => unknown;
}

export const VenuePageHeaderMobileContainer = (props: Props) => {
  const { i18n, generateUri, pageData } = React.useContext(Context);
  const { accountDetails, loadAccountDetails } = props;
  const isBrowser = useIsMounted();
  const { t } = useTranslation();
  const headerLinks = getHeaderLinks({
    isBrowser,
    i18n,
    generateUri,
    pageData,
  });
  const channel = pageData.channel;
  const pageType = getPageType(pageData) as keyof typeof pageData;
  const alternateLocaleUris =
    (pageData[pageType] && pageData[pageType].alternateLocaleUris) || [];
  const { user } = accountDetails;
  let isAuthenticated = null;
  let isConnectAccount = false;
  if (user) {
    isAuthenticated = user.isAuthenticated;
    isConnectAccount = user.isConnectAccount;
  }

  useEffect(() => {
    loadAccountDetails();
  }, [loadAccountDetails]);

  const { navigateLink, navigateText, onNavigateLinkClick } =
    generateNavigationTextAndLink({
      pageData,
      t,
      i18n,
    });

  return (
    <VenuePageHeaderMobile
      navigateAnchorLink={
        <NavigateAnchorLink
          navigateLink={navigateLink}
          navigateText={navigateText}
          onNavigateLinkClick={onNavigateLinkClick}
          icon={<IconArrowBack size={24} />}
        />
      }
      venueHeaderLinks={
        <VenueHeaderLinks
          channel={channel}
          alternateLocaleUris={alternateLocaleUris}
          headerLinks={headerLinks}
          isAuthenticated={isAuthenticated}
          isConnectAccount={isConnectAccount}
        />
      }
    />
  );
};

const mapStateToProps = (state: ReduxState) => ({
  accountDetails: getAccountDetails(state),
});

const mapDispatchToProps = {
  loadAccountDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenuePageHeaderMobileContainer);

export const NavigateAnchorLink = ({
  navigateLink,
  navigateText,
  onNavigateLinkClick,
  icon,
}: {
  navigateLink: string;
  navigateText: string;
  onNavigateLinkClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  icon?: Icon;
}) => {
  return (
    <a
      href={navigateLink}
      className={styles.navigateLink}
      data-cy="NavigateLinkComponent"
      onClick={onNavigateLinkClick}
    >
      <MediaObject space="xxs" align="center">
        {icon !== undefined ? icon : <IconChevronLeft size={16} />}
        <Text type="caption">{navigateText}</Text>
      </MediaObject>
    </a>
  );
};

export type VenueHeaderLinksType = {
  channel: Channel;
  alternateLocaleUris: [];
  headerLinks: Links;
  isAuthenticated?: boolean | null;
  isConnectAccount: boolean;
};

export const VenueHeaderLinks = ({
  channel,
  alternateLocaleUris,
  headerLinks,
  isAuthenticated,
  isConnectAccount,
}: VenueHeaderLinksType) => {
  return (
    <Inline space="xs">
      <LanguageSelector
        countryCode={channel.country.countryCode}
        languageCode={channel.languageCode}
        availableLanguages={channel.availableLanguages}
        alternateLocaleUris={alternateLocaleUris}
      />
      <HeaderLinks
        isAuthenticated={isAuthenticated}
        isConnectAccount={isConnectAccount}
        links={headerLinks}
      />
    </Inline>
  );
};

export const generateNavigationTextAndLink = ({
  pageData,
  t,
  i18n,
}: {
  pageData: StateData;
  t: TFunction;
  i18n: i18nFunction;
}) => {
  let navigateLink: string = '';
  let navigateText: string = t('venuePage.navigateLink.text');

  if (!pageData.venue) {
    return { navigateLink, navigateText };
  }

  if (pageData.venue.venue.type?.pluralName) {
    navigateText = i18n(
      'page.venue.section-titles.venues-nearby',
      pageData.venue.venue.type.pluralName
    );
    // in some scenarios a venue's type can be missing.
    // if that is the case, we will check if the venue has a subtype
    // and if it does we can use the pluralName of that. If neither
    // of them exist, we will just default to using "Back"
  } else if (
    Array.isArray(pageData.venue.venue.subTypes) &&
    pageData.venue.venue.subTypes.length > 0
  ) {
    navigateText = i18n(
      'page.venue.section-titles.venues-nearby',
      pageData.venue.venue.subTypes[0].pluralName
    );
  }
  if (pageData.venuePageReferrer) {
    navigateLink = pageData.venuePageReferrer;
    navigateText = t('venuePage.navigateLink.text');
  } else if (pageData.venue.hasBreadCrumbs) {
    const breadcrumbs = getBreadcrumbs(pageData.venue);
    navigateLink = breadcrumbs[breadcrumbs.length - 1].uri;
  } else {
    navigateLink = '/places';
  }

  const onNavigateLinkClick = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const currentTarget = event.currentTarget;
    await trackVenueHeaderExperimentBackClick(navigateText);
    if (pageData.venuePageReferrer) {
      window.history.back();
    } else {
      window.location.href = currentTarget.href;
    }
  };

  return { navigateLink, navigateText, onNavigateLinkClick };
};
