import { storage } from '@treatwell/ui';
import flattenDeep from 'lodash.flattendeep';

const key = (venueId) => `venue-basket-${venueId}`;

const persist = (venueId, services) =>
  storage.local.setItem(key(venueId), {
    services,
    updated: new Date().getTime(),
  });

const getStoredItems = (venueId) => {
  const data = storage.local.getItem(key(venueId));
  if (data === null) {
    return JSON.parse('{"services":[]}');
  }
  return data;
};

const getSkuFromMenuItem = (menuItem, skuId) => {
  if (menuItem.data.optionGroups) {
    return flattenDeep(
      menuItem.data.optionGroups.map((optionGroup) =>
        optionGroup.options.filter((option) => String(option.id) === skuId)
      )
    )[0];
  }
  return menuItem.data.options.filter(
    (option) => String(option.id) === skuId
  )[0];
};

const getSkuPrice = (menuGroups, groupId, prefixedServiceId, skuId) => {
  const group = menuGroups.filter((g) => String(g.id) === groupId)[0];

  const menuItem = group.menuItems.filter(
    (item) => item.data.id === prefixedServiceId
  )[0];

  const sku = getSkuFromMenuItem(menuItem, skuId);
  const skuPrice = sku.priceRange.minSalePriceAmount;

  return parseFloat(skuPrice);
};

/*
 * Update the venue basket with the selected services and skus.
 *
 * This a little bit involved, because the structure of the selections
 * is more suited to maintaining the selection in the UI than the needs
 * of the persisted venue basket.
 */
export const persistVenueBasket = (
  venueId,
  menuGroups,
  selectedGroups,
  fulfillmentType
) => {
  const services = [].concat(
    ...Object.keys(selectedGroups).map((groupId) => {
      const group = selectedGroups[groupId];

      return Object.keys(group).map((prefixedServiceId) => {
        const service = group[prefixedServiceId];
        // Remove the alpha prefix (such as "TR"), that appears to indicate
        // the service type.
        const serviceId = /[A-Z]+(\d+)/.exec(prefixedServiceId)[1];

        return {
          fulfillmentType,
          serviceId: parseInt(serviceId, 10),
          skus: Object.keys(service).map((skuId) => ({
            choiceId: parseInt(skuId, 10),
            price: getSkuPrice(menuGroups, groupId, prefixedServiceId, skuId),
          })),
        };
      });
    })
  );

  persist(venueId, services);
};

export const setFulfillment = (venueId, fulfillment) => {
  const services = getStoredItems(venueId).services;

  const setServices = services.map((service) => ({
    ...service,
    fulfillmentType: fulfillment,
  }));

  persist(venueId, setServices);
};

export const retrieveCurrentBasketItems = (venueId, menu) => {
  const getItemOptions = (menuGroup, menuItem) => {
    const getBasketOption = (item, options) => ({
      skuId: item.id,
      serviceId: menuItem.data.id,
      menuId: menuGroup.id,
      multi: options.length > 1,
      treatmentType: menuItem.data.primaryTreatmentCategoryGroupId,
    });
    if (!menuItem.data.optionGroups) {
      return menuItem.data.options.map((item) =>
        getBasketOption(item, menuItem.data.options)
      );
    }
    return flattenDeep(
      menuItem.data.optionGroups.map((optionGroup) =>
        optionGroup.options.map((item) =>
          getBasketOption(item, optionGroup.options)
        )
      )
    );
  };

  const { services } = getStoredItems(venueId);

  const menuGroupMap = flattenDeep(
    menu.menuGroups.map((menuGroup) =>
      menuGroup.menuItems.map((menuItem) => getItemOptions(menuGroup, menuItem))
    )
  );

  const choiceMap = flattenDeep(
    services.map((service) =>
      service.skus.map((sku) => ({ skuId: sku.choiceId }))
    )
  );

  return choiceMap.map((service) =>
    menuGroupMap.filter(
      (menuGroup) => parseInt(menuGroup.skuId, 10) === service.skuId
    )
  );
};

// Make private methods accessible to tests
export const Private = {
  getSkuPrice,
  persist,
};
