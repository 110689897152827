import {useEffect, useRef} from 'react';
import {loadScript} from '../loadScript';
import {WretchError} from '../http';
import {PromptMomentNotification} from 'google-one-tap';

export type CallbackParams = {
  credential: string;
};

export type Reason =
  | ReturnType<PromptMomentNotification['getDismissedReason']>
  | ReturnType<PromptMomentNotification['getSkippedReason']>;

export type Props = {
  environment: 'prod' | 'dev';
  handleCredentialResponse: ({credential}: CallbackParams) => Promise<unknown>;
  onSuccess?: () => void;
  onError?: (error: Error | WretchError) => void;
  onModalDisplay?: ({auto}?: {auto: true}) => void;
  onModalDismiss?: (reason: Reason) => void;
  onGoogleToken?: (googleToken: string) => void;
};

const googleClientId = {
  dev: '264165021098-vkjlpil6i5di4gchmkjcpn135pud1ldn.apps.googleusercontent.com',
  prod: '264165021098-fbkcjus8ljvqqjf6jathpf2mqq29lqjd.apps.googleusercontent.com',
};

export const GoogleOneTapSignIn = ({
  environment,
  handleCredentialResponse,
  onSuccess,
  onError,
  onModalDisplay,
  onModalDismiss,
  onGoogleToken,
}: Props) => {
  const clientId = googleClientId[environment];
  const isInitialised = useRef(false);

  useEffect(() => {
    loadScript({
      src: 'https://accounts.google.com/gsi/client',
      id: 'google-client-script',
    })
      .then(() => {
        if (window.google && clientId && !isInitialised.current) {
          // Initialize the One Tap prompt
          isInitialised.current = true;
          window.google.accounts.id.initialize({
            client_id: clientId,
            cancel_on_tap_outside: true,
            itp_support: true,
            context: 'signin',
            callback: async (response) => {
              // track auto log in prompt
              if (response.select_by === 'auto') {
                onModalDisplay?.({auto: true});
              }
              onGoogleToken?.(response.credential);
              // Response handling
              try {
                await handleCredentialResponse(response);
                onSuccess?.();
              } catch (error) {
                if (error instanceof Error) {
                  onError?.(error);
                }
              }
            },
            auto_select: true, // enable automatic login if user logged in with one tap previously
          });

          // Show the One Tap prompt
          window.google.accounts.id.prompt((notification) => {
            // access the state of the notification
            if (notification.isDisplayed()) {
              onModalDisplay?.();
            } else if (notification.isSkippedMoment()) {
              onModalDismiss?.(notification.getSkippedReason());
            } else if (notification.isDismissedMoment()) {
              onModalDismiss?.(notification.getDismissedReason());
            }
          });
        }
      })
      .catch(() => {});
  }, [
    clientId,
    handleCredentialResponse,
    onSuccess,
    onError,
    onModalDismiss,
    onModalDisplay,
    onGoogleToken,
  ]);

  return null;
};
