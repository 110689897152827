import React from 'react';

export default function AssetSuccessCheckbox({ positioningClassName, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={positioningClassName}
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <path
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 20l8 8L44 8"
        />
        <path
          stroke={color}
          strokeWidth="2"
          d="M34 12H8a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h32a2 2 0 0 0 2-2V16"
        />
      </g>
    </svg>
  );
}
