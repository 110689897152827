import {useCallback, useRef, DependencyList} from 'react';

export const useAnimationFrame = (cb: () => void, deps: DependencyList) => {
  const timeout = useRef<number>(undefined);
  const handler = useCallback(() => {
    if (timeout.current) {
      window.cancelAnimationFrame(timeout.current);
    }
    timeout.current = window.requestAnimationFrame(() => {
      cb();
    });
  }, deps);
  return handler;
};
