import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import classnames from 'classnames';
import styles from './style.module.css';

const preventDefault = (evt) => evt.preventDefault();

class Overlay extends React.Component {
  render() {
    const { onClick, fullpage } = this.props;
    const wrapperClasses = classnames(styles.wrapper, {
      [styles.fullpage]: fullpage,
    });

    return (
      <div key="overlay" onClick={onClick}>
        <div className={wrapperClasses} onTouchMove={preventDefault} />
      </div>
    );
  }
}

const PageOverlay = ({ onClick }) => {
  return (
    <CSSTransitionGroup
      transitionName="anm-fade"
      transitionEnterTimeout={0}
      transitionLeaveTimeout={0}
    >
      <Overlay onClick={onClick} key="main-overlay" fullpage />
    </CSSTransitionGroup>
  );
};

export default PageOverlay;
