import React from 'react';
import { CmsCommon } from 'js/model/cms/cms-common';
import { HighPriorityMessagingBannerView } from 'js/components/HighPriorityMessagingBanner/HighPriorityMessagingBannerView';
import { sha1 } from 'hash.js';
import { storage } from '@treatwell/ui';

export function createOptionalHighPriorityMessagingBanner(cms: {
  common?: CmsCommon;
}): React.ReactElement | null {
  const bannerCms = cms.common?.['high-priority-messaging-banner'];

  if (bannerCms === undefined) {
    return null;
  }

  if (bannerCms.enabled !== 'true') {
    return null;
  }

  if (
    bannerCms.heading === undefined ||
    bannerCms.text === undefined ||
    bannerCms['close-button-label'] === undefined
  ) {
    return null;
  }

  let moreInfoButton:
    | React.ComponentProps<
        typeof HighPriorityMessagingBannerView
      >['moreInfoButton']
    | undefined;

  if (
    bannerCms['more-info-button-label'] !== undefined &&
    bannerCms['more-info-button-url']
  ) {
    moreInfoButton = {
      label: bannerCms['more-info-button-label'],
      url: bannerCms['more-info-button-url'],
    };
  }

  return (
    <HighPriorityMessagingBanner
      key="HighPriorityMessagingBanner"
      heading={bannerCms.heading}
      text={bannerCms.text}
      closeButtonLabel={bannerCms['close-button-label']}
      moreInfoButton={moreInfoButton}
    />
  );
}

interface State {
  isVisible: boolean;
}

class HighPriorityMessagingBanner extends React.PureComponent<
  React.ComponentProps<typeof HighPriorityMessagingBannerView>,
  State
> {
  private readonly LOCAL_STORAGE_KEY =
    'high-priority-messaging-banner-closed-hash';

  public state = {
    isVisible: false,
  };

  public componentDidMount(): void {
    const seenHash = storage.local.getItem(this.LOCAL_STORAGE_KEY);

    if (seenHash !== this.propsHash()) {
      this.setState({ isVisible: true });
    }
  }

  private onCloseClick = () => {
    this.setState({ isVisible: false });
    storage.local.setItem(this.LOCAL_STORAGE_KEY, this.propsHash());
  };

  private propsHash(): string {
    return sha1().update(JSON.stringify(this.props)).digest('hex');
  }

  public render(): React.ReactNode {
    if (!this.state.isVisible) {
      return null;
    }

    return (
      <HighPriorityMessagingBannerView
        {...this.props}
        onCloseClick={this.onCloseClick}
      />
    );
  }
}
